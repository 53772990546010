import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  margin: 0 0 75px 0;

  & > img {
    max-width: 100%;
    max-height: 533px;
    width: auto;
    margin: 0 auto;
    position: relative;
    display: block;
  }

  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }
`;

const SingleImage = props => (
  <ImageContainer>
    <img src={props.image} alt={props.copyright} />
  </ImageContainer>
);
export default SingleImage;
