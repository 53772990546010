import React from 'react';
import styled from 'styled-components';

const LineElement = styled.hr`
  border: 0;
  border-bottom: 2px solid ${props => props.color || '#ffe100'};
  margin: ${props => props.margin || '0'};

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '0'};
  }
`;

export const Line = props => (
  <LineElement
    margin={props.margin}
    mobileMargin={props.mobileMargin}
    color={props.color}
  />
);
