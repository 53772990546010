import React, { useState, useEffect } from 'react';
import { Col } from 'styled-bootstrap-grid';
import LazyLoad from 'react-lazyload';

// Components
import EventCard from './eventCard';

const EventContainer = `
  margin-bottom: 36px;

  @media (max-width: 575px) {
    margin-bottom: 18px;
  }
`;

export const ListLocationEvents = ({ allEvents, color, textColor }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const formattedProducts = allEvents.map(product => {
      let sku = 'sku_' + product.node.prismicId;
      return (product.node = {
        ...product.node,
        ...product.data,
        activePrice: 'full_price',
        quantity: 1,
        sku: sku,
        productType: 'event',
      });
    });

    setProducts(formattedProducts);
  }, [allEvents]);

  const setActivePrice = (price, sku) => {
    const updatedProducts = products.map(product => {
      if (product.sku === sku) {
        product.activePrice = price;
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const content = products.map((product, index) => {
    const formattedProductData = {
      name:
        product.activePrice === 'full_price'
          ? product.data.title.text
          : `${product.data.title.text} – Members Discount`,
      id:
        product.activePrice === 'full_price'
          ? product.sku
          : `${product.sku}_discounted`,
      price:
        product.activePrice === 'full_price'
          ? product.data.full_price * 100
          : product.data.discounted_price * 100,
      currency: 'GBP',
      image: product.data.image,
      uid: `/timetable/event/${product.uid}`,
      details: product.data,
      productType: `event`,
    };

    return (
      <Col col={6} sm={6} lg={3} css={EventContainer} key={index}>
        <LazyLoad height={500}>
          <EventCard
            link={'/timetable/event/' + product.uid}
            details={product.data}
            date={product.data.date}
            cardType="event"
            color={color}
            textColor={textColor}
            sku={product.sku}
            onClick={(price, sku) => setActivePrice(price, sku)}
            activePrice={product.activePrice}
          />
        </LazyLoad>
      </Col>
    );
  });

  return <>{content}</>;
};
